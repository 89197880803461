<template>
  <div class="edit-drawer">
    <el-drawer
      :visible.sync="exist"
      :direction="direction"
      :before-close="handleClose"
      :show-close="false"
    >
      <div slot="title" class="title-wrap">
        <div class="left">
          <i class="el-icon-close" @click="handleClose"></i>
          <span>{{ saveType === "add" ? "新增标签" : "修改标签" }}</span>
        </div>
        <div class="right">
          <el-button type="text" @click="handleClose">取消</el-button>
          <el-button type="primary" round @click="handleSave" :loading="saving"
            >保存</el-button
          >
        </div>
      </div>
      <div class="drawer-content">
        <el-form
          :model="formData"
          :rules="formRule"
          ref="form"
          class="edit-form"
          size="small"
          label-width="100px"
        >
          <cond-card>
            <el-form-item label="标签中文名称" prop="chineseName">
              <el-input
                v-model="formData.chineseName"
                placeholder="请输入标签中文名称"
                style="width: 440px"
              ></el-input>
            </el-form-item>
            <el-form-item label="标签英文名称" prop="englishName">
              <el-input
                v-model="formData.englishName"
                placeholder="请输入标签英文名称"
                style="width: 440px"
              ></el-input>
            </el-form-item>
            <el-form-item label="标签分类" prop="tagType">
              <el-select v-model="formData.tagType" style="width: 152px">
                <el-option
                  v-for="item in tagTypeList"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="数据类型" prop="dataType">
              <el-select v-model="formData.dataType" style="width: 152px">
                <el-option
                  v-for="item in dataTypeList"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
              <span class="other-item" v-if="formData.dataType === '数值'">
                <span class="label">小数位数：</span>
                <el-select v-model="formData.pointNum" style="width: 100px">
                  <el-option label="1" :value="1"></el-option>
                  <el-option label="2" :value="2"></el-option>
                  <el-option label="3" :value="3"></el-option>
                  <el-option label="4" :value="4"></el-option>
                  <el-option label="5" :value="5"></el-option>
                </el-select>
              </span>
              <span class="other-item" v-if="formData.dataType === '日期/时间'">
                <span class="label">时间格式：</span>
                <el-date-picker
                  v-model="formData.datetime"
                  type="datetime"
                  placeholder="选择日期时间"
                >
                </el-date-picker>
              </span>
              <span class="other-item" v-if="formData.dataType === '百分比'">
                <span class="label">小数位数：</span>
                <el-select v-model="formData.pointNum" style="width: 100px">
                  <el-option label="1" :value="1"></el-option>
                  <el-option label="2" :value="2"></el-option>
                  <el-option label="3" :value="3"></el-option>
                  <el-option label="4" :value="4"></el-option>
                  <el-option label="5" :value="5"></el-option>
                </el-select>
              </span>
              <span class="other-item" v-if="formData.dataType === '货币'">
                <span class="label">小数位数：</span>
                <el-select v-model="formData.pointNum" style="width: 100px">
                  <el-option label="1" :value="1"></el-option>
                  <el-option label="2" :value="2"></el-option>
                  <el-option label="3" :value="3"></el-option>
                  <el-option label="4" :value="4"></el-option>
                  <el-option label="5" :value="5"></el-option>
                </el-select>
                <span class="label">货币符号：</span>
                <el-select v-model="formData.symbol" style="width: 100px">
                  <el-option label="¥" value="¥"></el-option>
                  <el-option label="$" value="$"></el-option>
                </el-select>
              </span>
            </el-form-item>
            <el-form-item label="" prop="demo">
              <div class="demo">
                <div>示例：</div>
                <div>{{ demoText[formData.dataType] }}</div>
              </div>
            </el-form-item>
          </cond-card>

          <cond-card title="标签计算公式">
            <el-form-item label="计算公式说明" prop="declare">
              <el-input
                v-model="formData.declare"
                placeholder="请输入计算公式说明"
                style="width: 440px"
              ></el-input>
            </el-form-item>
            <div class="formula-header">
              <span>计算公式</span>
              <div>
                <el-button type="text">插入字段</el-button>
                <el-button type="text">预发检测</el-button>
              </div>
            </div>
            <div class="formula-content">
              <el-input type="textarea" :rows="10" v-model="formData.formula">
              </el-input>
            </div>
          </cond-card>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import // apiAddChannel,
// apiUpdateChannel,
// apiGetChannelDetail,
"@/api/channel";
import condCard from "@/components/cond-card";
export default {
  components: {
    condCard,
  },
  props: {
    exist: {
      type: Boolean,
      default: false,
    },
    saveType: {
      type: String,
      default: () => "add",
    },
    detail: {
      type: Object,
      default: () => {return {}},
    },
  },
  data() {
    return {
      saving: false,
      direction: "rtl",
      formData: {
        chineseName: "",
        englishName: "",
        tagType: "活跃标签",
        dataType: "文本",
        declare: "",
        formula: "",
        pointNum: 2,
        datetime: "",
        symbol: "¥",
      },
      formRule: {
        Name: {
          required: true,
          message: "请输入权限名称",
          trigger: "blur",
        },
      },
      tagTypeList: [
        "活跃标签",
        "消费标签",
        "用户偏好标签",
        "车辆偏好标签",
        "未分类",
      ],
      dataTypeList: ["文本", "数值", "日期/时间", "百分比", "货币"],
      demoText: {
        文本: "活跃标签",
        数值: "123.00",
        "日期/时间": "2012/03/14  13:00",
        百分比: "25.13%",
        货币: "¥100.00",
      },
    };
  },
  computed: {
    ...mapGetters({
      channelLabelMap: "common/channelLabelMap",
    }),
  },
  watch: {
    exist(newVal) {
      if (newVal === true) {
        this.getUserDetail();
      } else {
        this.resetForm();
      }
    },
  },
  methods: {
    handleClose() {
      this.$emit("update:exist", false);
    },
    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitForm();
        }
      });
    },
    submitForm() {
      const param = {
        ...this.formData,
      };
      // this.saving = true;
      // const param = {
      //   ...this.formData,
      // };
      // let prom;
      // if (this.saveType === "add") {
      //   prom = apiAddChannel(param);
      // } else {
      //   prom = apiUpdateChannel(this.detail._id, param);
      // }
      // prom
      //   .then((res) => {
      //     this.$message({
      //       type: "success",
      //       message: "保存成功",
      //     });
      //     this.$emit("success", res);
      //     console.log(res);
      //   })
      //   .finally(() => {
      //     this.saving = false;
      //   });
    },
    resetForm() {
      this.formData = {
        Name: "",
        Desc: "",
      };
      this.$refs.form.clearValidate();
    },
    getUserDetail() {
      if (this.saveType === "edit") {
        // apiGetChannelDetail(this.detail._id).then((res) => {
        //   Object.assign(this.formData, {
        //     Name: res.Name,
        //     AppID: res.AppID,
        //     AppSecret: res.AppSecret,
        //   });
        // });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.edit-drawer {
  /deep/ .el-drawer {
    width: 72% !important;
    background: #f6f7f8;
    .el-drawer__header {
      margin-bottom: 16px;
      padding: 0;
    }
    .el-drawer__body {
      padding: 0 30px;
      overflow: auto;
    }
  }
  .title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    padding: 0 30px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);
    .left {
      font-size: 24px;
      line-height: 32px;
      i {
        margin-right: 22px;
        cursor: pointer;
      }
    }
  }
  .demo {
    height: 80px;
    padding: 11px 20px;
    background: #f6f7f8;
    div:nth-child(1) {
      margin-bottom: 11px;
      font-size: 11px;
      line-height: 18px;
      color: #747378;
    }
    div:nth-child(2) {
      font-size: 14px;
      line-height: 22px;
    }
  }
  .formula-header {
    display: flex;
    justify-content: space-between;
    line-height: 40px;
    font-size: 12px;
    color: #606266;
  }
  .other-item {
    .label {
      margin: 0 9px 0 25px;
    }
  }
}
</style>
