<template>
  <div class="page-customer">
    <content-header
      :title="title"
      :breadList="['客户中心', '标签管理', '车辆标签']"
    >
    </content-header>

    <div class="content-wrapper" v-resize="resizeChart">
      <div class="left">
        <!-- <el-button
          type="primary"
          round
          style="margin-bottom: 20px"
          @click="
            existDrawer = true;
            editType = 'add';
          "
          >创建新的标签</el-button
        > -->
        <!-- <el-button type="primary" round style="margin-bottom: 20px"
          >创建新的标签</el-button
        > -->
        <el-input
          v-model="filterText"
          prefix-icon="el-icon-search"
          placeholder="搜索..."
        ></el-input>
        <el-tree
          v-loading="loading"
          :data="treeData"
          node-key="Id"
          :default-expanded-keys="[defaultExpandedKeys]"
          :expand-on-click-node="false"
          :render-content="renderContent"
          icon-class="el-icon-arrow-right"
          class="tag-tree"
          :filter-node-method="filterNode"
          @node-click="treeClick"
          ref="tree"
        >
        </el-tree>
      </div>
      <div class="right" v-loading="loading">
        <div class="header">
          <span class="title">{{ chineseName }}</span>
          <span class="status">{{ tagType }}</span>
        </div>
        <div class="labels">
          <el-row>
            <el-col :span="8">
              <div class="label">标签中文名称</div>
              <div class="value">{{ chineseName }}</div>
            </el-col>
            <el-col :span="8">
              <div class="label">标签英文名称</div>
              <div class="value">{{ englishName }}</div>
            </el-col>
            <el-col :span="8">
              <div class="label">数据类型</div>
              <div class="value">{{ dataType }}</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <div class="label">标签来源</div>
              <div class="value">{{ tagSource }}</div>
            </el-col>
            <el-col :span="8">
              <div class="label">数据来源系统</div>
              <div class="value">{{ dataSourceSystem }}</div>
            </el-col>
            <el-col :span="8">
              <div class="label">标签更新</div>
              <div class="value">{{ tagUpdateDate }}</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="label">标签计算逻辑</div>
              <div class="value">
                <el-input
                  type="textarea"
                  class="textarea"
                  autosize
                  readonly
                  rows="3"
                  v-model="tagCountLogic"
                ></el-input>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="charts">
          <el-tabs v-model="activeTab">
            <el-tab-pane label="标签分布" name="tagDistribution">
              <chart
                ref="tagChart"
                :options="option"
                style="width: 100%"
              ></chart>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>

    <edit-drawer
      :exist.sync="existDrawer"
      :saveType="editType"
      :detail="curTag"
      @success="handleSaved"
    ></edit-drawer>

    <el-dialog title="删除确认" :visible.sync="delConfirmShow" width="30%">
      <span>确定删除该标签吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delConfirmShow = fa好Tfrdes21lse" type="text"
          >取 消</el-button
        >
        <el-button type="primary" round>确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ContentHeader from "@/views/layout/content-header";
import moment from "moment";
import EditDrawer from "../components/edit-drawer";
import { apiGetCarLabel, apiGetCarChart } from "@/api/tag";

export default {
  components: {
    EditDrawer,
    ContentHeader,
  },
  watch: {
    //监听搜索框
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  data() {
    return {
      loading: true,
      title: this.$route.meta.title,
      filterText: "",
      existDrawer: false,
      editType: "add",
      curTag: {},
      delConfirmShow: false,
      activeTab: "tagDistribution",
      nowId: "1",
      chineseName: "",
      englishName: "",
      tagSource: "",
      tagType: "",
      dataType: "",
      dataSourceSystem: "",
      tagUpdateDate: "",
      tagCountLogic: "",
      option: {},
      treeData: [],
      defaultExpandedKeys: "",
      color: [
        "#36A3FC",
        "#44DCEE",
        "#8CC270",
        "#F2B540",
        "#EB8F47",
        "#E44E93",
        "#B27AB8",
        "#999999",
      ],
    };
  },
  async mounted() {
    const res = await apiGetCarLabel();
    this.zhuanhuan(res);
  },
  methods: {
    handleSaved() {
      this.existDrawer = false;
    },
    renderContent(h, { node, data }) {
      return h("div", [
        h("icon-svg", {
          attrs: {
            name: data.LogoImg
              ? data.LogoImg
              : data.children
              ? "folder"
              : "tag",
          },
        }),
        h("i", {
          attrs: {
            class: "iconfont",
          },
        }),
        h("span", data.CnName),
        data.count
          ? h(
              "span",
              {
                attrs: {
                  class: "num-circle",
                },
              },
              data.count
            )
          : "",
      ]);
    },
    //树形控件筛选
    filterNode(value, data) {
      if (!value) return true;
      return data.CnName.indexOf(value) !== -1;
    },
    treeClick(data) {
      if (!data.children) {
        this.nowId = data.id;
        this.chineseName = data.CnName;
        this.englishName = data.EnName;
        this.tagType = data.Category;
        this.tagSource = data.TagGroup;
        this.dataType = data.DataType;
        this.dataSourceSystem = data.Source;
        this.tagUpdateDate = moment(data.UpdatedDate).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        this.tagCountLogic = data.Description;
        this.loading = true;
        apiGetCarChart(data.ColName)
          .then((res) => {
            let xData = [];
            let yData = [];
            let bingData = [];
            res.data.map((item, index) => {
              if (item.x !== null && item.x != 0) {
                xData.push(item.x);
                yData.push(item.y);
                bingData.push({ value: item.y, name: item.x });
              }
            });
            switch (data.DisplayGraphType) {
              case "柱状图":
                this.option = {
                  color: this.color,
                  tooltip: {
                    trigger: "axis",
                    axisPointer: {
                      type: "line",
                      lineStyle: {
                        type: "solid",
                      },
                    },
                  },
                  grid: {
                    top: "7%",
                    left: "1%",
                    right: "1%",
                    bottom: "7%",
                    containLabel: true,
                  },
                  xAxis: {
                    data: [...xData],
                    type: "category",
                    axisLabel: { rotate: 30 },
                  },
                  yAxis: {
                    type: "value",
                    axisTick: {
                      show: false,
                    },
                    axisLine: {
                      show: false,
                    },
                  },
                  dataZoom: [
                    {
                      type: "inside",
                      start: 0,
                      end: 100,
                    },
                    {
                      show: true,
                      type: "slider",
                      showDetail: false,
                      bottom: "0%",
                      height: 15,
                      handleSize: "10%",
                      start: 0,
                      end: 100,
                    },
                  ],
                  series: [
                    {
                      data: [...yData],
                      name: "数量",
                      type: "bar",
                      barMaxWidth: 20,
                    },
                  ],
                };
                break;
              case "折线图":
                this.option = {
                  color: this.color,
                  tooltip: {
                    trigger: "axis",
                    axisPointer: {
                      type: "line",
                      lineStyle: {
                        type: "solid",
                      },
                    },
                  },
                  grid: {
                    top: "7%",
                    left: "1%",
                    right: "1%",
                    bottom: "7%",
                    containLabel: true,
                  },
                  xAxis: {
                    data: [...xData],
                    axisLabel: { rotate: 30 },
                  },
                  yAxis: {
                    type: "value",
                    axisTick: {
                      show: false,
                    },
                    axisLine: {
                      show: false,
                    },
                  },
                  dataZoom: [
                    {
                      type: "inside",
                      start: 0,
                      end: 100,
                    },
                    {
                      show: true,
                      type: "slider",
                      showDetail: false,
                      bottom: "0%",
                      height: 15,
                      handleSize: "10%",
                      start: 0,
                      end: 100,
                    },
                  ],
                  series: [
                    {
                      data: [...yData],
                      type: "line",
                      name: "数量",
                    },
                  ],
                };
                break;
              case "饼图":
                this.option = {
                  color: this.color,
                  tooltip: {
                    trigger: "item",
                  },
                  series: [
                    {
                      type: "pie",
                      data: [...bingData],
                    },
                  ],
                };
                break;
              case "面积图":
                this.option = {
                  color: this.color,
                  tooltip: {
                    trigger: "axis",
                    axisPointer: {
                      type: "line",
                      lineStyle: {
                        type: "solid",
                      },
                    },
                  },
                  grid: {
                    top: "7%",
                    left: "1%",
                    right: "1%",
                    bottom: "7%",
                    containLabel: true,
                  },
                  xAxis: {
                    data: [...xData],
                    axisLabel: { rotate: 30 },
                    boundaryGap: false,
                  },
                  yAxis: {
                    type: "value",
                    axisTick: {
                      show: false,
                    },
                    axisLine: {
                      show: false,
                    },
                  },
                  dataZoom: [
                    {
                      type: "inside",
                      start: 0,
                      end: 100,
                    },
                    {
                      show: true,
                      type: "slider",
                      showDetail: false,
                      bottom: "0%",
                      height: 15,
                      handleSize: "10%",
                      start: 0,
                      end: 100,
                    },
                  ],
                  series: [
                    {
                      data: [...yData],
                      type: "line",
                      name: "数量",
                      areaStyle: {},
                    },
                  ],
                };
                break;
              default:
                this.option = {
                  color: this.color,
                  xAxis: {
                    data: [0],
                  },
                  yAxis: {
                    type: "value",
                  },
                  series: [
                    {
                      data: [0],
                      type: "line",
                    },
                  ],
                };
            }
          })
          .catch(() => {
            this.option = {
              xAxis: {
                data: [null],
              },
              yAxis: {
                type: "value",
              },
              series: [
                {
                  data: [null],
                  type: "line",
                },
              ],
            };
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    resizeChart() {
      if (this.$refs.tagChart) {
        this.$refs.tagChart.resize();
      }
    },
    zhuanhuan(data) {
      let changeData = data;
      let nowData = "";
      changeData.forEach((item, key) => {
        changeData[key]["count"] = 0;
        if (item.children) {
          item.children.forEach((i1, k1) => {
            changeData[key]["children"][k1]["count"] = 0;
            if (i1.children) {
              i1.children.forEach((i2, k2) => {
                changeData[key]["children"][k1]["children"][k2]["count"] = 0;
                if (i2.children) {
                  i2.children.forEach((i3, k3) => {
                    changeData[key]["children"][k1]["children"][k2]["children"][
                      k3
                    ]["count"] = 0;
                    if (i3.children) {
                      i3.children.forEach((i4, k4) => {
                        changeData[key]["count"]++;
                        changeData[key]["children"][k1]["count"]++;
                        changeData[key]["children"][k1]["children"][k2][
                          "count"
                        ]++;
                        changeData[key]["children"][k1]["children"][k2][
                          "children"
                        ][k3]["count"]++;
                        (nowData==="") && (nowData = i4);
                      });
                    } else {
                      changeData[key]["count"]++;
                      changeData[key]["children"][k1]["count"]++;
                      changeData[key]["children"][k1]["children"][k2][
                        "count"
                      ]++;
                      (nowData==="") && (nowData = i3);
                    }
                  });
                } else {
                  changeData[key]["count"]++;
                  changeData[key]["children"][k1]["count"]++;
                  (nowData==="") && (nowData = i2);
                }
              });
            } else {
              changeData[key]["count"]++;
              (nowData==="") && (nowData = i1);
            }
          });
        }else{
          (nowData==="") && (nowData = item);
        }
      });
      this.treeData = changeData;
      this.defaultExpandedKeys = nowData.Id;
      this.treeClick(nowData);
      this.loading = false;
    },
  },
};
</script>

<style lang="less" scoped>
.page-customer {
  .content-wrapper {
    display: flex;
  }
  .left {
    flex: 0 0 auto;
    width: 300px;
    padding: 0 20px 20px 0px;
    /deep/.el-input__inner {
      height: 36px;
      border: 0;
      border-radius: 6px;
      background: #f3f5f6;
    }
    .tag-tree {
      margin-top: 16px;
      overflow: auto;
      flex: 1;
      /deep/.iconfont {
        margin-right: 5px;
        vertical-align: middle;
      }
      /deep/.num-circle {
        display: inline-block;
        margin-left: 10px;
        width: 22px;
        height: 22px;
        line-height: 22px;
        border-radius: 50%;
        font-size: 12px;
        text-align: center;
        background: #d7dcdf;
      }
      /deep/.el-tree-node__content {
        height: 38px;
        font-size: 14px;
        .expanded {
          margin-right: 4px;
          font-weight: bold;
          color: #747378;
        }
      }
      /deep/.el-tree-node__children {
        overflow: visible !important;
      }
    }
  }
  .right {
    flex: 1 1 auto;
    .header {
      margin-bottom: 36px;
      .title {
        font-size: 34px;
        line-height: 36px;
        color: #26262c;
      }
      .status {
        display: inline-block;
        vertical-align: bottom;
        margin-left: 13px;
        height: 22px;
        line-height: 22px;
        padding: 0 7px;
        font-size: 11px;
        background: #19b977;
        border-radius: 20px;
        color: #fff;
      }
    }
    .labels {
      /deep/.el-row {
        margin-bottom: 36px;
      }
      .label {
        margin-bottom: 4px;
        font-size: 12px;
        line-height: 19px;
        color: #747378;
      }
      .value {
        font-size: 16px;
        line-height: 25px;
        color: #26262c;
      }
    }
    .charts {
      border: 1px solid #dddddf;
      border-radius: 6px;
      padding: 0 18px;
      /deep/.el-tabs__item {
        height: 44px;
        line-height: 44px;
      }
    }
  }
}
</style>
